import React from 'react'
import TypeWriter from 'react-typewriter'

class MyFace extends React.Component {
  render() {
    return (
      <div id="my-face" className="border">
        <div className={"ascii content"}>
          <TypeWriter
            fixed={true}
            maxDelay={0}
            minDelay={0}
            typing={1}>
            {/*"║░    '╬║▓▓▓▓▓▓▓▓▓▓▌╬░░╙░░░░░                           `╙░░░░░░╣   ╣▓▓▓▓▓▓░╠╬░░"}<br/>
            {"░▌     ╣╟▓╣▓▓▓▓▓╬▓▓▌╬░░░░╛`                                 `░░░╣   ╣▓▓▓▓▓▓░░░░░"}<br/>
            {"░░     ║░▓╣╣╣▓▓╣▓╣▓▓░▀ ⁿ                                      ╙▀║   ╫▓▓▓▓╣▓░░░░░"}<br/>
            {"⌐╠     ▐░▓╬╬╠╬╬╣▓╬▀                                             ▀   ║▓▓╣╬╣▓∩⌠░░░"}<br/>
            {"░▐∩    .░▓╬╬╬░╣▀╙                                                   ║▓╣╬╬║▓∩⌡░░░"}<br/>
            {"░⌠░     ░║▌░░░┘                                                     ╙▓╬╬╣░▓⌐.:░░"}<br/>
            {"░⌠░     ╠░▓░░                 .╔╦░░µ░╔╓                              ⌠▀██▓▓░≥φ╦╦"}<br/>
            {",;⌠     ╘░▀▓▓ε            .░░░░╠╬╬╣╣╬╬╬╬╦░╦╓                          ⌠░░░░░░░░░"}<br/>
            {"░⌠      ░░░░            .░░░░░╣╬▓╣▓▓▓▓▓▓▓▓▓╣╦ε»                       ⌡░░░╙░░╙░"}<br/>
            {"░       ╘╙╧╛            ¡░░╬╬╬╬╬╣▓▓▓▓▓▓▓▓▓▓▓▓▓╬░░╦╔⌐,,,                ''  ''.."}<br/>
            {"░       ''⌐            '░░╬╬╬╣▓▓▓╣▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓╣╬╬╣╬░░░░░ε╕           '''⌡⌡⌡┐"}<br/>
            {"                       .░░╣╣╬▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒▓▓╬╣▒░⌐"}<br/>
            {"                        ░░╣╣╬╣▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓╬╬░"}<br/>
            {"                       ⌡╬╬╣╬▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒░"}<br/>
            {"                     ,░░░╬╣╣╬╬▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓░"}<br/>
            {"                   ;░╬░╬░╬╬╬╬╣▓▓▓▓▓▓▓╣▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌"}<br/>
            {"          ]░╦     φ╣╣╬░░░╬╩░░' ''╙╙▀╩╬░╣╬╣▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌⌐"}<br/>
            {"          ⌡>░░   ,▀╬╬░░░`       -      '╙░╠╬╬╬▓▓▓▓▓▓╣▓▓╬▓▓▓▓▓▓▓▓▓░"}<br/>
            {"          ░░░░  ║╬░` `'   .   ~⌐╓         '░░░░╬░░░░░░▀╣╣▓╣▒▒▓▓▓▓░"}<br/>
            {"          ░╬░░ ║╬╬░░░░ `                   ⌡░░░░░░'          ╘░▓▓╣⌐"}<br/>
            {"          ╬░░ ]╣░╬░╬╬░:░░⌐,⌐..            '⌡░░░╙─              '╠╣∩"}<br/>
            {"          ░░░░░░╣╣╬╣╬╬░░╬╬░╦⌐  ''     :⌐~  ░╬▓╬░          ''=   ]░░ ╕"}<br/>
            {"          ╠░░░░╣╣╬╬╣╣╣▌╚╣╣▓╣╣╣▒▒╣╣╬░░░░ε  ╔╣▓▓▓░              └'╙╙  ."}<br/>
            {"          '╬░╬░╠╬░╬░╬╬╬▒░╬╣▓▓▓▓▓▓▓▓▓╣░░`╔╬╣╣▓▓▓░  ╓,             ░.⌐"}<br/>
            {"           ╠╬░░░░░░╬╣╣╬╣╬░░▀╬▒╬╬╬▓╬╬╬╜╔╣╣╣╣▓▓▓▓░╣⌐╠╬░░╦╦╓,,,≤░░░░⌐╬"}<br/>
            {"            ╣╬░░░╬╬╬╬╬▒╣╬╣╣▒╦▄▄╠╠░▄╦╣╣░░╣╬▓▓▓▓▓╬░░'╣╣╣╣╬╬░╬╣░╬╬╬░╬░"}<br/>
            {"             '░░░░╣╬╬╬╬╣▓▓▓▓▓▓▓▓▓▓▓░░╣╣▓╬▓▓▓▓▓▓▓░░░╖╙╣╬╬╬▓▓▓╣▀░╣╬░"}<br/>
            {"              ╚░░░░░░░░╬╬╣╬▒╣╬░░╬░░░░▀▀╬░╬╣╣▓▓▓▓╣▒░╬╣▒╦▄▄╠▄╦▒╬╬╬░░"}<br/>
            {"              ⌠░░░░░░░╬╬╬░╬░░╬░░╬╬╬╬░    ╙░░░░░░░░░░╠╬╬▓▓▓▓╣░░░░░"}<br/>
            {"              '░░░░░░░░░░░░░░░░╬╣╣╬╬░░░░\'       .╦╬░░░░╬╣╬░░░░░⌐"}<br/>
            {"               ░░░░░░░░╬╬░░░░░░░░░░░░░░░░   |░░░░░░░░░░░░░░░░░''"}<br/>
            {"               '░░░░░░╣╬╣░░░░░┘░░░░░░░░░░░░░░░░░⌐░░░░░░░░░░░░'"}<br/>
            {"                ░░░░░░░░╬╬░░╬╣▒φ╦╓╓╓⌡⌡╙╙░╩╬░░░░░░⌡''⌡⌠░░░░░⌐`"}<br/>
            {"                ╔░░░░░░░╬░╬╣╣▓▓▓▓╣╬╬╬╣╣▒▒╦╦▄╖╓,,  ,,,░░░░░░`"}<br/>
            {"                ░░░░░░░░░░░╣╣╬╣▓▓╬╬╬░░░░░╬╬░╬░░░░░░░╬░░░░⌐"}<br/>
            {"                ░░░░░░╬╬╬╬╬╬╣╣╬╣╬╣╣╬╣╬░░░░░░░░░░░░░░░░░░'"}<br/>
            {"                ░░░░⌐░░░░░╬╬╣╣╣▓▓▓▓╬╣╬╬░░░░╬░░░░░░░░░░░⌐"}<br/>
            {"               ╔░░░░░░⌡╙░░░╬╬╣▓▓▓▓▓▓▓▓▓╬╣╣╣╣╬╬╬╬░░░░░⌐"}<br/>
            {"               ░░░░░░░░░⌐'░░╠╬╣╬╬╣▓▓▓▓▓▓▓▓▓▓▓╬╣░░░░░~"}<br/>
            {"              ╠░░░░░░░░░░░, '░░░╬╬░╣╬╬╬░╬╬╬╬╬░░░░'"}<br/>
            {"            ░░░░░░░░░░░░░░░⌐⌐   '╙░░░░░░░░░░░░░~"}<br/>
            {"          ╒░░░░░░░░░░░░░░░░░░░░;⌐     ' ''"}<br/>
            {"         .░░░░░░░░░░░░░░░░░░░░░░░┐'"}<br/>
            {"         ╘░░░░░░░░░░░░░░░░░░░░░░░░⌐,⌐                                gskrap 2018"*/}
            
            {/*"                          .╔@▒▌▌▌▌▌▌▓▓▓▓▓▓▌▒è╖"}<br/>
            {"                     ,æ▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒≡."}<br/>
            {"                   Æ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌╖"}<br/>
            {"                 ▄▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒"}<br/>
            {"               ╒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌"}<br/>
            {"              ╒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"              ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓╕"}<br/>
            {"             ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓⌐"}<br/>
            {"            ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"           ▌▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌"}<br/>
            {"          ]▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀█▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"          ║▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀▀╙'      .--░░░'' ░' =░⌐    ╙▀▓▓▓▓▓▓L"}<br/>
            {"          ║▓▓▓▓▓▓▓▓▓▓▓▀▀░^    '.'⌐'-`⌐,}''ⁿ-'^░` .`..       ╙▀▓▓▌"}<br/>
            {"           ▓▓▓▓▓▓▓▓▀░^'  ░⌐'.░░░░░⌐░░░░░,╓»─φ≥░░╠▄░╠░░░≥⌂─⌐    ▀▓"}<br/>
            {"           ▓▓▓▓▓▀░░  ⌐'░╓░╤╦░░░'⌐⌐░░░░░░╪╬╬▒╬╬╣╣╬▒╬▒▒▀▌▓▓▓█▌▄▄░"}<br/>
            {"           ▓▓▓░░    '''░░░░░.,░░░░╠╣▒▒╬╬╬╬╬╬╬╬╬╬╬╬╬▒▒▒▀▌▌▓▓▓▓▓▓▓▌mε"}<br/>
            {"           ▓▓▓▄     '.░░░░⌐,░░░▄▒▒▒╬▒╬╬╬╠░░░╚╠╠╬╬╬╣╬╣╣▒▀▌▌▓▓▓▓▓▓▓▓▓▄░"}<br/>
            {"          ░▓▓▓▓⌐   .''░░░░░╠▄▒▒▒▒╬╬╬╬╬╠░░░░░░░░░░░╠╠╬╬╬▒▒▀▀▓▓██▓▓▓▓▓▌░"}<br/>
            {"         ╒╦▄╠▓▓▓ε ` .░░░░▄▒▌▀▒▒╬╬╬░░░░░^^░░░'''''''░░░╠╬╬╣▒▀▀▒▒▓▓▓▓▓▓▌"}<br/>
            {"         ]▒▓▌░╬▓▓▌▄▄▄╣▒▀▌▓▓▓▓▓▓▓▓▌▌▒▄░╔⌐        ⌐╔╦▄▒▌▌▓▓▓▓▓▌▌▒█▓▓▓▓Ä"}<br/>
            {"          ╚Å▀▒╟█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌▒▒╗╦░░╦╗╣▒▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌"}<br/>
            {"          ░░╠╣▓█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓─"}<br/>
            {"          ╔░░╣▀░░█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█"}<br/>
            {"          ▌▄░╬▒░░░▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌╬╬▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"        ╒▒▓▓▄░╠╬░░╫▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌▒╬╬╠╠▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓╓║"}<br/>
            {"      ╔φ▒▀▓▓▓▄░╚░░░▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀╬╬░░░░╬▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓░+╠"}<br/>
            {"      X╣▌▓▓▓▓▓▄░╡░░░░▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀▒╬╬╬╬░░░░╠╬Å▀▓▓▓▓▓▓▓▓▓█▀▀╙'░ ╚"}<br/>
            {"    ╒╩╡╢▒▓▓▓▓▓▓░╬░░░░░░░╨▐▀▀▀▀▀▀▀╙  ░░╟╬╬╬░░░░░░░░░⌐' '^░░'     'j,."}<br/>
            {"    ╛├]▌▓▓▓▓▓▓▓▓▓░░░░░,⌐          ,φ╬╬╬╬╬░░░░'''╙╚░⌐            ▐ ╞"}<br/>
            {"   ╒ ░║▀╣▓▓▓▓▓▓▓▓▒░░░░░░⌐       ⌐░ '░░░░░░~       ,   '⌐           ╞"}<br/>
            {"   ' ╚╢▒▀▓▓▓▓▓▓▓▓▓░░░░░░⌐     ,░░░»░░░░░░░░⌐    ░   ⌐ .,░░░     ░   ⌐"}<br/>
            {"     .╫▒▓▓▓▓▓▓▓▓▓▓▄░░░░░░'''░░╠╬╣╬╬╬╠╟╠╬░░░░─'░.░░╦░░#╠╩╬░╚⌐   ░░   ∩"}<br/>
            {"     ⌐»Å▓▓▓▓▓▓▓▓▓▓╫╠░░░░░⌐  ░╠╬╬╬░░░░░░░'`       '`'⌡▄▒╬░░    '░⌐   µ,"}<br/>
            {"     »╞ ▀▓▓▓▓▓▓▓▓▓▓▌░░░░░░   ░░░╬▒▀▄▄  `' ⁿⁿ──ⁿ    ╣▒╨'        ╣▄▓▓▓▓▓"}<br/>
            {"      ╘ Å▓▓▓▓▓▓▓▓▓▓▓▌░░░░░'   ''░░░╟╬▒▒@▒╖╖╖╗╤æ▒▀▒╩░' '       ▓▓▓▓▓▓▌▓Æ╖"}<br/>
            {"      ⌡╓╠▓▓▓▓▓▓▓▓▓▓▌▒▒░░,,⌐     ░░░⌐''░░░^^'                X▓▓▓▓▓▓▓▓▓▓▓▓▌▒."}<br/>
            {"      ½▒▀▓▓▓▓▓▓▓▓▓▓▌▒╬╬░░,⌐      ''                       .█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌▒."}<br/>
            {"      ╬▌▓▓▓▓▓▓▓▓▓▓▌▀▒╬╬░░░░░░.⌐            -░░.          ╔▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌▌▒"}<br/>
            {"    a▌▓▓▓▓▓▓▓▓▓▓▓▓▀▒▒╬░░░░░░░░░⌐░                       ╒▓▓▌▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌▌▄"}<br/>
            {" ╔▒▌▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓╬╬╬░░░░░░░╨╬░░░'                   .∩]▓▓▌▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌▌"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▄░░░░░⌐░░~╙╢╗░░░                ╒  ╣▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌▌"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌▄░░░░⌐⌐   ╙╬░░⌐            ░    ╟▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█▄▄░⌐     '╚╝░░░   .,░░═`     .╢▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌▄╥      `'``           ╦╣▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█▄▄▄,           ,╓╣▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌▓"*/}            

            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀▀╜⌡⌡╘⌡⌡⌡╘└└└└╘Γ░▀▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀╜┘└''  ''''''''   '''''''└╙▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀÷÷^''`                     '  ''└╙▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀^^''                              '''└╜▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓,`                                      '└╜▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓                                           '⌐▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓▓⌐                                             ^▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓▓⌡                                              '^█▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓▓⌡                                                 .▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓b                                                  '▐▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓                      ,╓╖╦╦##ê@Æ@ê╗φ╗╗▄╖,,          `▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▌               ,╓╦╣▒▒▓▓▓▓▓▓╣╣╬░░▌▒▓╬▓▓▒╫╣▓▓▓█▄▄,    '▐▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▌          .╔φ╣╣╣▓▓▓╣╣╬╣╣▒▒▓╣▌▒▒▒▒╣╣▒╣╣╣╣╣▒▓▓▓▓▓▓▓▒[ `└▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓.      ,φ╣╣╣▓╬╬╬╣╣╣╣╣╣╣╬╬╣▒▒▒╣╣▒▒░░╬╬╬╬╬╣╬╣░░╬▒▓▓▓▓█▄⌐▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓    ,╦╣╣▓▓╣╬╬╣╣╣╣░╬╣╣╣╬╣╣░░╬░░░░░░░░░░░░░Sε⌡┐;/╘╘╙▀╬▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓   ╣╣▓▓▓▓╣╣╣╬╣╬╬╣╣╬╬╣╣░░░░░░░░░░░░░░░░░░░░░ε∩⌂:⌐     ╙╣▓▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▓.  ║▓▓▓▓▓╣╬╣╬╣╣╣╬░░░░░░░░░░░░░░░░░░░░░░░░░░░Γε⌂÷⌐      '╠▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▌⌐   ╣▓▓▓╬▒╣╬╬╬╬░░░░░░░░░░░░░░░░╣░╬░░░░░░░░░░░░░ε;,:╓     ╘╣▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▀╩░   ▒▓▓▓╬╬╣░░░░ΓΓ░░░░░░░╬╣╣╣╣╣╣╣╣╣╣╣╣╣╣╣╬░░░░░░Sε░░Γ  Γ. ╚▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▌░⌂╚╬░  ⌠░▀░░░Γ⌡┘^'`''└╘⌡╙╜░╬╬╣╣▓▓▓▓▓▓▓╬╬▀▀░╙╙┘```└╘Γ░⌂    ╠▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▒░ε░░└`                    '╙░░░░╬╬╣░░╙└                  `⌠▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓╣╬░Γ,╓ ²`'                                           .  └╘ ╫▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓▌╣░░⌡░╬⌐  '                                        '':,⌐ ╒▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▓▓│╚╬░░╬╬╬                        |░░ε               '`÷(  ▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▓▌Ö^ ╠╬░░╬░░                      ;░░░░░             '.,÷^ ╣░▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▓▌░ƒ`  ╚╣░╣╬░╦                   ,░░░░╬╬░░.           ''└`.╣░░▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓░░⌠`    ║░╬╬╬╬╬╓              .╔░░░░░░╬╬╬░░░╓,        ,▄#▒╣╣▓▌▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓░░░Γ`     ╬░╬╬╣╬╣╬╣▒▒ê╦╦╦╦#φ╣▒▓╬╣░░░░░╬╬╬╬╣░╬╣╣╣▓▒╣▒▒▓▓▓▓▓▓╬▌█▓▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓╣▌▌⌡⌂        ╢╬╬╬╬╣╣╣▓▓▓▓▓▓▓▓▓╣░░░░░░░░╬╣╬╬╣╬╬░╬╣▓▓▓▓▓▓▓▓▓▓▓▓▌▓▌▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▌▓╣▌pΓ        ╚░╬░╣╣╬╣▓▓▓▓▓▓▓▒╬╣╣╬╣╣╬╣╬╣╣▓▓▓▓▓▓▓▓▓▓▓╬▓▓▓▓▓▓▓▓▓▓▓▌▓▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓╫▓▌░░Γ        `╬░╬╬╣╣╣▓▓▓▓▓╬╣╬╬╬╬╬░╬╬╣╬╬╣▓▓▓▓╣▒▒▓▓▓╣╣╣╣╬▓▓▓▓▓╣▓▓▓╫▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▒░░⌂         ╠░░╬╬░╣╣▓╬╬░░░░░░░░░░░░╬░╬╣╬░╬╬╣░░╬░░░░╣░╬▓▓▓╬░▓▓▓╣▓▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓╣▌░>`        ╛░╬░╬░╣╣▓▓░░░░░╬╬╣╣╣╣╣▒╣▓▓▓▓▓▓▓╣▒╣▒▀░░╬╣╣▓▓▓▓▒█▓▓▓▀▀▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓╬▌▓∩~         `╬░╣░╬╣╣▓▓╬╬╬░░ε╚▀▓▓▓▓▓▓▓▓▓▓▓▓▓▓░░╣╬╣▓▓▓▓▓▓▓ÜÅ,,  '▓▓▓▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▌▓║¬         ,⌠░╬╣╬╬╣▓▓▓╣╣╬╬▒░░░░░▀▀▀▀▀▀▀░φ░╣╬╣▓▓▓▓▓▓▓▓▓└÷└÷÷└╘┘▀▀▀▓▓▓▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓▌▌░         '|░░╬╣╣╣╣▓▓▓▓╣╣╬╣╣╣╬╬╣╣╣╣╣▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▀.' .'⌐,`⌐└└(⌡╜▀▓▓▓▓"}<br/>
            {"▓▓▓▓▓▓╣░∩         ┌⌡░░░░╬╣╬▓▓▓▓▓╣╣╣╣▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓`'⌐`⌐''⌐'^⌐,^`└┘⌡╜▀▓▓"}<br/>
            {"▓▓▓▓▓▓░╙`        '|ε░░░░╬░░╬╬▓▒▓▓╣▓▓▓▓▓▓▓▓▓▒╣░╣▓▓▓▓▓▓▓▓▓▓▀`  └└⌐',,.'^''.^÷!|⌡╜▓"}<br/>
            {"▓▓▓▓▀╙           ÷⌡░░░░░╬╬╣░░╬╬╣╣▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▒  ] '⌐,`-,^⌐^⌐⌐^,╒÷└|⌡╢"}<br/>
            {"▓▀╜┘,             `░░░░╬╬╬╬╬╣╣░░╣╬╣▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓╬╣▌ ¬⌡ '`⌐└⌐⌐⌐⌐'^,⌐⌐⌐,«!⌡⌡"}<br/>
            {"└'÷└^               `╩░╬╬╬╣╣╬╣╣▒░░╣╬╬▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▌▓▓░ `[  ÷╒⌐÷└÷',-'÷÷÷÷÷¡⌂⌂"}<br/>
            {" ` '                   ⌠╩╣╣╣╬╣╬▓▓▒▒░╬╣╣▓▓▓▓▓▓▓▓▓▓▓▓╣▓▓▓▓░⌐  .⌐ⁿ`:';÷÷÷÷,⌐'÷÷:÷(⌂"}<br/>
            {"'`                        └╙▀╣╣▓▓▓▓▓▒╬░░╣╬▓▓▓▒╣╣░░▓▓▓▓▓▓╣░` ⌐⌐,⌐'└└└÷÷÷÷⌐⌐.'└(*⌂"}<br/>
            {"                              '╙▀▀▓▓▓▓▓▓▓█▓▓▓▓▓▓▓▓▓▓▓▓▓░░`.`,⌐╒÷÷÷└÷÷÷÷÷ΓΓ⌐⌐'└└⌂"}<br/>
            {"                                   '╘╙▀▀█▓▓▓▓▓▓▓▓▓▓▓█▀░÷',,╒⌐⌐`÷÷÷└└÷÷÷└÷ΓΓ÷⌐,'└"}<br/>
            {"                                           ''''````..'.,,⌐÷'⌐,⌐└└¡/÷└÷÷÷└└Γ÷::╒,"}<br/>
            {"                                      ''.'.ΓΓ.....⌐⌐^''`'-,'÷÷`╒:└÷÷÷÷÷└÷÷÷÷└!⌂;"}<br/>          
          </TypeWriter>
        </div>
      </div>
    )
  }
}

export default MyFace
